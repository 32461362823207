<template>
    <div class="bodylist">
        <div class="tname">
        <p><img style="margin-left:20px" src="../../assets/image/logo.png" /> <span style="font-size:36px;margin-left:10px;font-weight: 800;">中联燃气</span> <img style="margin-left:50%" src="../../assets/image/logo_r.png"></p>
        </div>
        <div class="bgcontent">
            <div class="title">温馨提示</div>
             <div class="bcontent">
             安全用气要牢记，人走火灭阀关闭，室内用气常通风，燃气异味要冷静，开创关阀勿用气， 严禁明火不动电，速到室外求救援。
           </div>
           <div class="showcontent">
               <div class="show1">
                 <div class="bg1" @click="goPath('/indexAdd')">
                     <p class="titles">申请开户</p>
                     <p class="link">点击申请<icon class="el-icon-arrow-right"/></p>
                     </div>
                 <div class="bg2" @click="goPath('/progress')">
                     <p class="titles">进度查看</p>
                     <p class="link">点击查看<icon class="el-icon-arrow-right"/></p>
                 </div>
               </div>
               <img class="imgc" src="../../assets/indexpc/content3.png"/>
           </div>
        </div>
       
    </div>
</template>
<script>
 export default {
    name: 'home',
     methods: {
      goPath(path){
          this.$router.push({ path: path, query: {} })
      }
    }
 }
</script>

<style  scoped lang="scss">
.bodylist{
    margin: 0px 15%;
}
.tname {
  font-size: 0.5rem;
  text-align: center;
   margin-top:15px;
  p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 0.7rem;
    width: 100%;
    margin: 0 auto;
    
  }
}
.bgcontent{background-image: url('../../assets/indexpc/indexbg.png');background-size: 100% 100%;width: 100%;height: 100%;display: block;top:15px;position: relative;}
.title{
    font-size: 22px;
    font-weight: 800;
    color: #0D254C;
    padding: 80px 0px 18px 0px;
    border-bottom: 1px solid #0D254C;
    width: 156px;
    margin-left: 80px;
}
.bcontent{
    padding: 23px 115px 0px 80px;
    font-size: 20px;
    font-weight: bold;
    color: #566F9A;
    line-height: 32px;
}
.showcontent{
    padding: 91px 0px 0px 80px;
    }
.show1{display: inline-block;}
.bg1{background-image: url('../../assets/indexpc/content1.png');background-size: 100% 100%;width: 320px;height: 80px;}
.bg2{background-image: url('../../assets/indexpc/content2.png');background-size: 100% 100%;width: 320px;height: 80px;margin-top: 36px;}
.bg1 :hover{
    cursor:pointer
}
.bg2 :hover{
    cursor:pointer
}
.titles{
    font-size: 20px;
    font-weight: bold;
    color: #FFFFFF;
    padding:16px 0px 0px 34px;
    }
.link{
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    margin-left: 34px;
}
.imgc{margin-left: 145px;vertical-align:bottom;}
</style>